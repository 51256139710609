@import 'accents';

.native-table {
  [tuiWrapper][data-appearance='table'] {
    all: revert;

    &::before,
    &::after {
      display: none !important;
    }
  }

  input {
    // etc.
  }
}
