@import 'assets/stylesheets/00-variables';
@import 'assets/stylesheets/01-base';
@import 'assets/stylesheets/02-styles';

/* You can add global styles to this file, and also import other style files */
html {
  height: 100%;
}

:root {
  --apf-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  --apf-border: solid 1px #dcdcdc;
  --apf-border-radius: 0.3125rem;
  --apf-border-radius-m: 0.75rem;
}

tui-dialog[data-appearance='invoice'] {
  border-radius: var(--apf-border-radius);
  .t-content {
    padding: 0;
  }
}

body {
  height: 100%;
  max-width: 100%;
  background-size: initial;
  background-image: url('/assets/cart-bg.jpg') !important;
}

body.auth-image {
  background-image: url('/assets/login-background-transparent.png');
}

:root {
  --tui-background-accent-1: #5097d7;
}

.root-container {
  min-height: calc(100vh - 66px);
}
.view {
  width: 100%;
  max-width: 100%;
}

.price {
  white-space: nowrap;
  color: var(--tui-background-accent-1);
  padding-left: 5px;
  padding-right: 5px;
  font-size: 17px;
  font-weight: bold;
}

.card {
  background: var(--tui-background-base);
  border-radius: var(--apf-border-radius);
  padding: 1rem 1.5rem;
  border: var(--apf-border);
  box-shadow: var(--apf-box-shadow);
}

button.apf-button {
  border-radius: 2px;
  cursor: pointer;
}

a.btn {
  border-radius: 2px;
}

label {
  span {
    tui-input.apf-input {
      border-radius: var(--apf-border-radius);
    }
  }
}

[tuiAppearance][data-appearance='apf-outline-primary-btn'] {
  background: transparent;
  border: 1px solid var(--tui-background-accent-1);
  color: var(--tui-background-accent-1);

  &:hover {
    background: var(--tui-text-action-hover);
    color: var(--tui-background-base);

    transition-property: color, background, opacity;
    transition: all 500ms ease-out;
  }
}

[tuiAppearance][data-appearance='apf-neutral-btn'] {
  background: transparent;
  border: 1.5px solid var(--apf-neutral);
  border-radius: 0.5rem;

  &:hover {
    border-color: var(--tui-text-primary);

    transition-property: color, background, opacity;
    transition: all 500ms ease-out;

    > svg {
      color: var(--tui-background-base);
    }
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.display-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-FlexEnd {
  justify-content: flex-end;
}

.justify-content-spaceBetween {
  justify-content: space-between;
}

.justify-content-spaceAround {
  justify-content: space-around;
}

.align-itemsFlexEnd {
  align-items: flex-end;
}

.align-itemsCenter {
  align-items: center;
}

.align-itemsFlexStart {
  align-items: flex-start;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.flexDirection-column {
  flex-direction: column;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-grow-1 {
  flex-grow: 1;
}

.container-body {
  background-color: #fbfbfc;
  min-height: calc(100vh - 180px);
  padding: 0 1.875rem;
  max-width: 100vw;
}

.order-filter-container {
  display: grid;
  grid-template-columns: 1fr 31.25rem;
  row-gap: 1rem;
  column-gap: 1rem;
  align-items: center;
  margin: 0 0 0.6rem 0;

  @media (max-width: 70rem) {
    max-width: 100%;
    grid-template-columns: 1fr;
  }

  > *:last-child {
    align-items: end;
    align-content: end;
    text-align: right;
  }
}

.no-wrap {
  white-space: nowrap;
}

.filter-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;

  @media (max-width: 70rem) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 56rem) {
    grid-template-columns: 1fr;
  }
}

tui-dialog[data-appearance='apf-modal'] {
  top: 5rem;
  margin: 1.25rem auto;
  border: none;
  border-radius: 0.4rem;
}

/* Hubspot Forms */
div#hubspotForm {
  font-family:
    'Manrope',
    -apple-system,
    'BlinkMacSystemFont',
    system-ui,
    'Roboto',
    'Segoe UI',
    'Helvetica Neue',
    sans-serif;
  font-size: 14px;

  > form {
    hs-error-msgs {
      font-weight: 500;
      font-size: 12px;
      color: #dd4c1e;
    }

    input[type='text'],
    input[type='email'],
    input[type='number'] {
      line-height: 1.5;
      width: 100%;
      height: 35px;
      padding-left: 0.5rem;
      border: 2px solid transparent;
      border-radius: 5px;
      outline: none;
      background-color: #f8fafc;
      color: #0d0c22;
      transition: 0.5s ease;
    }

    input#firstname-0b969d37-53b4-44cd-a2f4-1849e6711be8 {
      width: 95%;
    }

    fieldset {
      margin-bottom: 7px;
    }

    label {
      font-weight: 600;
    }

    li > label {
      font-weight: 400;
    }

    .hs_submit {
      transition-property: color, background, opacity;
      transition: all 700ms ease-out;

      input.hs-button.primary.large {
        padding: 0.5rem 1rem;
        width: 100%;
        cursor: pointer;
      }
    }
  }

  input[type='submit'] {
    margin-top: 1rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    background: #b33fec;
    border: none;
    color: white;
    font-weight: 500;
    border-radius: 4px;

    &:hover {
      background: var(--tui-background-accent-1-hover);
    }
  }
}

._disabled {
  cursor: not-allowed;
}

tui-icon .close {
  cursor: pointer;
}
