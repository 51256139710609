[tuiTheme='seller'] tui-textfield {
  width: 100%;
}

[tuiTheme='seller'] tui-select [tuiWrapper][data-appearance='textfield'] {
  box-shadow: none !important;

  &:hover {
    box-shadow: 0 0.125rem 0.313rem 0 var(--apf-black-15) !important;
  }
}

[tuiTheme='seller'] [tuiAppearance][data-appearance='textfield'] {
  --t-shadow: none;
  --tui-border-normal: var(--apf-gray-color);
  &:hover {
    --t-shadow: 0 0.125rem 0.313rem 0 var(--apf-black-15);
    --tui-border-normal: var(--apf-gray-dk-color-30);
  }
}

[tuiTheme='seller'] tui-textfield[data-size='s'] {
  &[iconstart] {
    &::after {
      content: '';
      position: absolute;
      display: flex;
      left: 1.375rem;
      top: 50%;
      transform: translateY(-50%);
      height: 1.375rem;
      width: 1px;
      background-color: var(--apf-gray-color);
    }
    input {
      padding-left: 1.375rem;
    }
  }
}

[tuiTheme='seller'] tui-textfield[data-size='m'] {
  --tui-height-m: 2.75rem;
  --tui-border-focus: var(--apf-blue-purple);
  --tui-font-text-s: 0.813rem;

  padding: 0 0.5rem 0 1rem;
  --tui-text-tertiary: var(--apf-gray-dk-color);

  input.ng-touched.ng-invalid {
    outline-color: var(--apf-red-color);
  }

  &[iconstart] {
    &::after {
      content: '';
      position: absolute;
      display: flex;
      left: 2rem;
      top: 50%;
      transform: translateY(-50%);
      height: 2rem;
      width: 1px;
      background-color: var(--apf-gray-color);
    }
    input {
      padding-left: 2rem;
    }
  }
}
[tuiTheme='seller'] label {
  --tui-text-primary: var(--apf-gray-dk-color);
  .tui-required {
    &::after {
      color: var(--apf-red-color);
    }
  }
}
[tuiTheme='seller'] tui-textfield::before {
  font-size: 0.813rem !important;
  font-weight: 500;
}

[tuiTheme='seller'] label[tuiLabel]:has(tui-textfield._disabled) {
  cursor: not-allowed;
  opacity: var(--tui-disabled-opacity);
}

[tuiTheme='seller'] tui-error {
  --tui-text-negative: var(--apf-red-dk-color);
  --tui-font-text-s: 0.688rem;
}

[tuiTheme='seller']
  [tuiAppearance][data-appearance='primary']:is(
    a,
    button,
    select,
    textarea,
    input,
    label
  ):hover:not(:disabled):not([data-state]) {
  --tui-background-accent-1-hover: var(--apf-purple-dk-color);
}

[tuiTheme='seller'] [tuiAppearance][data-appearance='primary'] {
  --t-bg: var(--apf-purple-color);
  --tui-background-accent-1-pressed: var(--apf-purple-color);
}

[tuiTheme='seller'] [tuiLabel]:has(tui-textfield),
[tuiLabel]:has(tui-primitive-textfield),
[tuiLabel]:has(tui-textarea) {
  font: var(--apf-label-size) !important;
  font-weight: 400;
  line-height: 1rem;
}

[tuiTheme='seller']
  [tuiAppearance][data-appearance='textfield'][data-mode~='invalid'][data-mode~='invalid'] {
  // even if you do not have validation, it will show red when touched unless this is overridden
  --tui-status-negative: var(--apf-gray-color);
}

[tuiTheme='seller'] [tuiLabel] input[type='checkbox'] {
  --tui-radius-s: var(--apf-radius-s);
}

[tuiTheme='seller']
  [tuiWrapper][data-appearance='textfield']._focused._focused:after {
  color: var(--apf-blue-purple);
}

[tuiTheme='seller']
  [tuiAppearance][data-appearance='file']:is(
    a,
    button,
    select,
    textarea,
    input,
    label
  ) {
  &.ng-touched.ng-invalid {
    border-color: var(--apf-red-color);
  }
}

[tuiTheme='buyer'] tui-combo-box {
  border-radius: var(--apf-border-radius);
}

[tuiTheme='seller'] chassis-state {
  label {
    --tui-text-primary: var(--tui-text-secondary);
    gap: 0;
  }
}
