[tuiTheme='seller'] [tuiradio] {
  box-shadow: none;
  &:not(:checked) {
    --tui-background-neutral-1: #d2d7e9;
    background: var(--tui-background-neutral-1);
  }

  &:hover:not([disabled]) {
    --tui-background-neutral-1: #d2d7e9;
    background: var(--tui-background-neutral-1);
    box-shadow: none;
  }

  &[disabled][data-size='s'] {
    box-shadow: none;
    --tui-background-neutral-1: #e3e7f5;
    background: var(--tui-background-neutral-1);
    cursor: not-allowed;
    pointer-events: all;
  }

  &[disabled][data-size='m'] {
    --tui-background-neutral-1: #e3e7f5;
    background: var(--tui-background-neutral-1);
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: all;
  }
}

[tuiTheme='seller'] label:has(input[type='radio']) {
  display: flex;
  align-items: center;
  opacity: 90%;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    pointer-events: all;
  }
}

[tuiTheme='seller'] .normal-align {
  label:has(input[type='radio']) {
    align-items: normal;
  }
}

[tuiTheme='seller'] label:has(input[type='radio'][data-size='s']) {
  font-size: 0.813rem;
}
[tuiTheme='seller'] label:has(input[type='radio'][data-size='m']) {
  font-size: 1.219rem;
}

[tuiTheme='seller'] label:has(input[type='radio'][disabled]) {
  color: #292d32;
  opacity: 56%;
  font-weight: 500;
  &:hover {
    cursor: not-allowed;
    pointer-events: all;
  }
}
