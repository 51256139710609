:root {
  // Borders
  [tuiTheme='seller'] {
    --tui-radius-s: 0.75rem;
    --tui-radius-m: 0.75rem;
    --apf-border: 0.063rem solid var(--apf-border-color);
  }

  [tuiTheme='buyer'] {
    --tui-radius-s: 0.125rem;
  }
}
