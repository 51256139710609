:root {
  [tuiTheme='buyer'] {
    --apf-h2-size: 1.75rem;
    --apf-h2-height: 1.75rem;

    --apf-h3-size: 1.3rem;
    --apf-h3-height: 1.3rem;

    --apf-h4-size: 1rem;
    --apf-h4-height: 1rem;

    --apf-bold: 700;

    h2 {
      font-size: var(--apf-h2-size);
      line-height: var(--apf-h2-height);
      font-weight: var(--apf-bold);
    }

    h3 {
      font-size: var(--apf-h3-size);
      line-height: var(--apf-h3-height);
      font-weight: var(--apf-bold);
    }

    h4 {
      font-size: var(--apf-h4-size);
      line-height: var(--apf-h4-height);
      font-weight: var(--apf-bold);
      margin: 0;
    }
  }
  [tuiTheme='seller'] {
    --apf-standard-height: 1.5rem;

    --apf-h1-size: 2.813rem;
    --apf-h1-height: auto;
    --apf-h2-size: var(--apf-standard-height);
    --apf-h2-height: 1.75rem;
    --apf-h3-size: 1.25rem;
    --apf-h3-height: var(--apf-standard-height);
    --apf-h4-size: 0.938rem;
    --apf-h4-height: var(--apf-standard-height);
    --apf-h5-size: 0.813rem;
    --apf-h5-height: 1.25rem;

    --apf-bold: 700;
    --apf-extra-bold: 800;
    --apf-medium: 500;

    --apf-p-one-size: 0.938rem;
    --apf-p-two-size: 0.875rem;
    --apf-p-three-size: 0.813rem;

    --apf-caption-size: 0.688rem;
    --apf-label-size: 0.688rem;

    h1 {
      font-size: var(--apf-h1-size);
      line-height: var(--apf-h1-height);
      font-weight: var(--apf-bold);
    }
    h2 {
      font-size: var(--apf-h2-size);
      line-height: var(--apf-h2-height);
      font-weight: var(--apf-extra-bold);
    }
    h3 {
      font-size: var(--apf-h3-size);
      line-height: var(--apf-h3-height);
      font-weight: var(--apf-extra-bold);
    }
    h4 {
      font-size: var(--apf-h4-size);
      line-height: var(--apf-h4-height);
      font-weight: var(--apf-extra-bold);
    }
    h5 {
      font-size: var(--apf-h5-size);
      line-height: var(--apf-h5-height);
      font-weight: var(--apf-extra-bold);
    }

    .p1 {
      font-size: var(--apf-p-one-size);
      line-height: var(--apf-standard-height);
      font-weight: var(--apf-medium);
    }

    .p2 {
      font-size: var(--apf-p-two-size);
      line-height: var(--apf-standard-height);
      font-weight: var(--apf-medium);
    }

    .p3 {
      font-size: var(--apf-p-three-size);
      line-height: var(--apf-standard-height);
      font-weight: var(--apf-medium);
    }

    .caption {
      font-size: var(--apf-caption-size);
      line-height: 1rem;
      font-weight: var(--apf-medium);
    }

    .extra-bold {
      font-weight: var(--apf-extra-bold);
    }

    .bold {
      font-weight: var(--apf-bold);
    }
  }
}
