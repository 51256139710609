[tuiTheme='seller'] tui-pagination {
  --tui-text-action: var(--apf-gray-dk-color);
  color: var(--apf-white);
  --tui-disabled-opacity: 0.3;

  &:hover {
    --tui-background-neutral-1-hover: var(--apf-background-neutral);
  }

  [tuiButton][tuiAppearance][data-size='s'] {
    width: 1.8725rem;
    height: 2rem;
    border-radius: 0.75rem;
    padding: 0 0.5rem;
    box-shadow: none;
    font-weight: 600;
    &:hover {
      color: var(--tui-primary);
    }

    &[disabled] {
      opacity: 3;
    }
  }
}
