[tuiTheme='seller'] [tuiWrapper][data-appearance='textfield'] {
  box-shadow: none;
  &:active {
    box-shadow: var(--apf-shadow);
  }
  &._focused._focused::after {
    --tui-border-focus: var(--apf-blue-purple);
  }
}

tui-dropdown {
  --tui-shadow-medium: 0 -0.188rem 1rem 0 #0000000d,
    0 -0.625rem 0688rem 0 #0000000a, 0 -1.563rem 0.938rem 0 #00000008;
}
