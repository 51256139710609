[tuiTheme='buyer'] [tuiSwitch] {
  --switch-primary: var(--apf-blue-color);
  --switch-background: #e3e7f5;
  --switch-background-hover: #d2d7e9;

  --tui-background-neutral-1: var(--switch-background);
  --tui-background-neutral-1-hover: var(--switch-background-hover);

  --tui-background-neutral-2: var(--switch-background);
  --tui-background-neutral-2-hover: var(--switch-background-hover);

  --tui-background-base: #ffffff;
  --tui-background-accent-1-pressed: var(--switch-background);
  --tui-background-neutral-2-pressed: var(--switch-background);

  background: var(--tui-background-neutral-1);

  &:hover {
    --tui-background-neutral-1: var(--switch-background-hover);
  }

  &[disabled][data-size='m'] {
    cursor: not-allowed;
    pointer-events: all;
    &:hover {
      --tui-background-neutral-1-hover: var(--switch-background);
      --tui-background-neutral-2-hover: var(--switch-background);
    }
  }

  &[disabled][data-size='s'] {
    cursor: not-allowed;
    pointer-events: all;
    &:hover {
      --tui-background-neutral-1-hover: var(--switch-background);
      --tui-background-neutral-2-hover: var(--switch-background);
    }
  }

  &[data-size='m'] {
    width: 3.25rem;
    height: 1.75rem;
    border-radius: 2.5rem;
    &:after {
      inline-size: 1.8rem;
      box-shadow:
        -4.5rem 0 0 1.2rem var(--tui-background-base),
        -4.5rem 0.125rem 0.188rem 1.2rem #0000001a;
      right: -0.05rem;
    }

    &[data-appearance='primary'] {
      &::after {
        box-shadow:
          -4.5rem 0 0 0.7rem var(--switch-primary),
          -4.5rem 0 0 1.2rem white,
          -4.5rem 0.125rem 0.188rem 1.2rem rgba(0, 0, 0, 0.1019607843);
      }
    }

    &:checked {
      &::after {
        box-shadow:
          -4.5rem 0 0 0.7rem var(--switch-primary),
          -4.5rem 0 0 1.2rem white,
          -4.5rem 0.125rem 0.188rem 1.2rem rgba(0, 0, 0, 0.1019607843);
      }
    }
  }

  &[data-size='s'] {
    &:after {
      box-shadow:
        -2.625rem 0 0 0.7rem var(--tui-background-base),
        -2.625rem 0.125rem 0.188rem 0.7rem #0000001a;
    }

    &:checked {
      &::after {
        box-shadow:
          -2.625rem 0 0 0.5rem var(--switch-primary),
          -2.625rem 0 0 0.7rem white,
          -2.625rem 0.125rem 0.188rem 0.7rem rgba(0, 0, 0, 0.1019607843);
      }
    }
  }
}

[tuiTheme='seller'] [tuiSwitch] {
  --switch-primary: var(--apf-purple-color);
  --switch-background: #e3e7f5;
  --switch-background-hover: #d2d7e9;

  --tui-background-neutral-1: var(--switch-background);
  --tui-background-neutral-1-hover: var(--switch-background-hover);

  --tui-background-neutral-2: var(--switch-background);
  --tui-background-neutral-2-hover: var(--switch-background-hover);

  --tui-background-base: #ffffff;
  --tui-background-accent-1-pressed: var(--switch-background);
  --tui-background-neutral-2-pressed: var(--switch-background);

  &:hover {
    --tui-background-neutral-1: var(--switch-background-hover);
  }

  &[disabled][data-size='m'] {
    cursor: not-allowed;
    pointer-events: all;
    &:hover {
      --tui-background-neutral-1-hover: var(--switch-background);
      --tui-background-neutral-2-hover: var(--switch-background);
    }
  }

  &[disabled][data-size='s'] {
    cursor: not-allowed;
    pointer-events: all;
    &:hover {
      --tui-background-neutral-1-hover: var(--switch-background);
      --tui-background-neutral-2-hover: var(--switch-background);
    }
  }

  &[data-size='m'] {
    width: 3.25rem;
    height: 1.75rem;
    border-radius: 2.5rem;

    &:after {
      inline-size: 1.8rem;
      box-shadow:
        -4.5rem 0 0 1.2rem var(--tui-background-base),
        -4.5rem 0.125rem 0.188rem 1.2rem #0000001a;
      right: -0.05rem;
    }

    &[data-appearance='primary'] {
      &::after {
        box-shadow:
          -4.5rem 0 0 0.7rem var(--switch-primary),
          -4.5rem 0 0 1.2rem white,
          -4.5rem 0.125rem 0.188rem 1.2rem rgba(0, 0, 0, 0.1019607843);
      }
    }

    &:checked {
      &::after {
        box-shadow:
          -4.5rem 0 0 0.7rem var(--switch-primary),
          -4.5rem 0 0 1.2rem white,
          -4.5rem 0.125rem 0.188rem 1.2rem rgba(0, 0, 0, 0.1019607843);
      }
    }
  }

  &[data-size='s'] {
    &:after {
      box-shadow:
        -2.625rem 0 0 0.7rem var(--tui-background-base),
        -2.625rem 0.125rem 0.188rem 0.7rem #0000001a;
    }

    &:checked {
      &::after {
        box-shadow:
          -2.625rem 0 0 0.5rem var(--switch-primary),
          -2.625rem 0 0 0.7rem white,
          -2.625rem 0.125rem 0.188rem 0.7rem rgba(0, 0, 0, 0.1019607843);
      }
    }
  }
}
