[tuiTheme='seller'] .badge {
  padding: 0.1875rem 0.4375rem;

  font-size: 0.75rem;
  font-weight: var(--apf-medium);
  line-height: 1.125rem;

  color: var(--apf-purple-color);
  background: var(--apf-purple-lt-color-30);
  border-radius: var(--apf-radius-s);
}
