[tuiTheme='seller'] [tuiCheckbox] {
  &:hover:not([disabled]) {
    --tui-background-neutral-1: #d2d7e9;
  }
  box-shadow: none;
  &[disabled][data-size='m'] {
    cursor: not-allowed;
    pointer-events: all;
  }

  &[data-size='m'] {
    --tui-radius: 0.5rem;
    border-radius: var(--tui-radius);
  }

  &[disabled][data-size='s'] {
    --tui-background-neutral-1: #e3e7f5;
    cursor: not-allowed;
    pointer-events: all;
  }

  &[tuiLabel]:not([data-orientation='vertical'])[data-size='s'] {
    --tui-font-text-m: var(--apf-p-three-size);
  }
}

[tuiTheme='seller'] label:has(input[type='checkbox'][disabled]) {
  color: var(--apf-gray-dk-color);
  opacity: 56%;
  &:hover {
    cursor: not-allowed;
    pointer-events: all;
  }
}

[tuiTheme='seller'] label:has(input[type='checkbox']) {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  opacity: 90%;
  &:hover {
    cursor: pointer;
    pointer-events: all;
  }
}

[tuiTheme='seller'] [tuiCheckbox][data-appearance='outline-grayscale'] {
  --tui-background-neutral-1: #e3e7f5;
  margin-top: 0;
  background: var(--tui-background-neutral-1);
  box-shadow: none;
}
