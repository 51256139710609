@use 'sass:map';

@function hex-to-rgba($hex-color, $alpha: 1) {
  $r: red($hex-color);
  $g: green($hex-color);
  $b: blue($hex-color);
  @return rgba($r, $g, $b, $alpha);
}

@function hex-to-rgb($hex-color) {
  $r: red($hex-color);
  $g: green($hex-color);
  $b: blue($hex-color);
  @return rgb($r, $g, $b);
}

@mixin create-color-vars($colors) {
  @each $name, $color in $colors {
    --#{$name}: #{$color};
    --#{$name}-rgb: #{hex-to-rgb($color)};
    @each $alphaName, $alpha in $alphas {
      --#{$name}-#{$alphaName}: #{hex-to-rgba($color, $alpha)};
    }
  }
}

$alphas: (
  0: 0,
  10: 0.1,
  15: 0.15,
  20: 0.2,
  30: 0.3,
  40: 0.4,
  50: 0.5,
  60: 0.6,
  70: 0.7,
  80: 0.8,
  90: 0.9,
);
$colors: (
  apf-black: #000000,
  apf-white: #ffffff,
  apf-background-neutral: #e4ecf3,
  apf-blue: #1e4466,
  apf-blue-purple: #5d67cf,
  apf-border-color: #ededed,
  apf-gray-color: #e0e0e0,
  apf-gray-lt-color: #f2f5ff,
  apf-gray-ltr-color: #fbfbfc,
  apf-gray-md-color: #595959,
  apf-gray-dk-color: #292d32,
  apf-purple-color: #b33fec,
  apf-purple-lt-color: #d493f4,
  apf-purple-dk-color: #9a25d4,
  apf-red-color: #e5212d,
  apf-red-dk-color: #a5000a,
  apf-red-lt-color: #ffe4e4,
  apf-add-red-color: #e5212d,
  apf-blue-color: #5097d7,
  apf-blue-dk-color: #3f8acc,
  apf-blue-lt-color: #6cc7e3,
  apf-blue-ltr-color: #abd6fe,
  apf-green-color: #00a862,
  apf-green-lt-color: #c0f5ce,
  apf-green-md-color: #05a862,
  apf-green-dk-color: #13694a,
  apf-orange-lt-color: #ffe7d7,
  apf-orange-color: #fc7013,
  apf-orange-dk-color: #c24e01,
  apf-orange-add-lt-color: #ffe7d7,
  apf-yellow-color: #ffce2e,
  apf-midnight-blue: #00232e,
  apf-table-row: #e9ecf0,
  apf-table-column: #e9ecf0,
  apf-seller-border: #e8e8e8,
);

:root {
  @include create-color-vars($colors);
  // Output will be (for apf-black):
  // --apf-black: #000000;
  // --apf-black-rgb: rgb(0, 0, 0);
  // --apf-black-10: rgb(0, 0, 0, 0.1);

  --apf-shadow: var(--apf-black-10);

  [tuiTheme='seller'] {
    --tui-primary: var(--apf-purple-color);
    .success {
      color: var(--apf-green-color);
    }
  }

  [tuiTheme='buyer'] {
    --apf-success-color: #00b512;
  }
}
