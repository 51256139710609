:root {
  [tuiTheme='seller'] {
    --apf-radius-s: 0.5rem;

    --tui-duration: 100ms;
  }

  --tui-duration: 100ms;

  [tuiTheme='buyer'] {
  }
}
