[tuiButton][tuiAppearance][data-size='m'] {
  cursor: pointer;
  font-weight: 800;
  font-size: 0.938rem;
  line-height: 1.5rem;
  border-radius: var(--tui-radius-m);
  height: 2.75rem;
  gap: 0.438rem;
  padding: 0.625rem 1.5rem;
  border: 0.063rem solid transparent;

  &[disabled][data-size='m'] {
    cursor: not-allowed;
    pointer-events: all;
  }

  &[disabled][data-size='s'] {
    cursor: not-allowed;
    pointer-events: all;
  }
}

[tuiButton][tuiAppearance][data-size='s'] {
  --tui-height-s: 1.75rem;
  height: var(--tui-height-s);
  --tui-radius-s: 0.5rem;
  --t-padding: 0;
  border-radius: var(--tui-radius-s);
  width: 10rem;
  box-shadow: 0 2px 3px 0 var(--apf-shadow);
  font-size: 0.688rem;
  line-height: 1.25rem;
  gap: 0.375rem;
  font-weight: 800;
  padding: 0.25rem 0.875rem;
}

[tuiAppearance][data-appearance='destructive'][tuiButton] {
  cursor: pointer;
  color: var(--apf-white);
  border: 0.063rem solid var(--apf-add-red-color);
  --tui-background-neutral-1: var(--apf-red-color);

  &:hover:not([disabled]) {
    --tui-background-neutral-1-hover: var(--apf-red-dk-color);
    border: 0.063rem solid var(--apf-add-red-color);
    --tui-background-accent-1-hover: var(--apf-red-dk-color);
  }

  &:active {
    --tui-background-neutral-1-pressed: var(--apf-red-color);
  }

  &[disabled] {
  }
}

[tuiAppearance][data-appearance='outline-destructive'][tuiButton] {
  cursor: pointer;
  color: var(--apf-add-red-color);
  border: 0.063rem solid var(--apf-add-red-color);

  &:hover:not([disabled]) {
    --tui-background-neutral-1-hover: var(--apf-red-dk-color);
    border: 0.063rem solid var(--apf-add-red-color);
    --tui-background-accent-1-hover: var(--apf-red-dk-color);
  }

  &:active {
    --tui-background-accent-2-pressed: var(--apf-red-lt-color);
    background-color: var(--apf-red-lt-color);
  }

  &[data-size='s'] {
    &:hover:not([disabled]) {
      background-color: var(--apf-red-lt-color);
    }

    &:active:not([disabled]) {
      background-color: var(--apf-white);
    }
  }
}

[tuiAppearance][data-appearance='warning'][tuiButton] {
  color: var(--apf-orange-color);
  --tui-background-neutral-1: var(--apf-white);
  background-color: var(--apf-white);

  &:hover {
  }

  &[disabled] {
    opacity: 56%;
    cursor: not-allowed;
  }

  &[data-size='s'] {
    border: 0.063rem solid var(--apf-orange-add-lt-color);

    &:hover:not([disabled]) {
      --tui-background-accent-1-hover: var(--apf-orange-lt-color);
      background-color: var(--apf-orange-add-lt-color);
      border: 0.063rem solid var(--apf-orange-color);
      color: var(--apf-orange-color);
    }

    &:active:not([disabled]) {
      background-color: var(--apf-white);
    }
  }
}

// Whiteblock
[tuiAppearance][data-appearance='whiteblock'][tuiButton],
[tuiAppearance][data-appearance='whiteblock'][tuiIconButton] {
  --tui-background-neutral-1: var(--apf-gray-ltr-color);
  border: 0.063rem solid var(--apf-gray-color);
  box-shadow: 0 0.125rem 0.188rem 0 var(--apf-shadow);

  &:hover:not([disabled]) {
    border: 0.063rem solid var(--apf-gray-dk-color);
  }

  &:active:not([disabled]) {
    background-color: red;
    border: 0.063rem solid var(--apf-gray-color);
  }

  &[disabled] {
    border: 0.063rem solid var(--apf-gray-color);
  }
}

// Seller - Primary
[tuiTheme='seller'] [tuiAppearance][data-appearance='primary'][tuiButton] {
  color: white;
  border: 0.063rem solid var(--apf-purple-color);

  &:hover:not([disabled]) {
    --tui-background-accent-1-hover: var(--apf-purple-dk-color);
  }

  &:active:not([disabled]) {
    --tui-background-accent-1-pressed: var(--apf-purple-color);
  }

  &[disabled] {
    --tui-background-accent-1: var(--apf-purple-color);
  }

  &[data-size='s'] {
    &:hover:not([disabled]) {
      --tui-background-accent-1-hover: var(--apf-purple-dk-color);
      background-color: var(--tui-background-accent-1-hover);
      border: 0.063rem solid var(--apf-purple-color);
    }

    &:active:not([disabled]) {
      --tui-background-accent-1-pressed: var(--apf-purple-color);
      background-color: var(--tui-background-accent-1-pressed);
    }
  }
}

[tuiTheme='seller']
  [tuiAppearance][data-appearance='primary-invert'][tuiButton] {
  color: var(--apf-purple-color);
  border: 0.063rem solid var(--apf-purple-color);

  &:hover:not([disabled]) {
    background-color: var(--apf-purple-lt-color);
  }

  &:active:not([disabled]) {
    --tui-background-accent-1-pressed: white;
  }

  &[disabled] {
    --tui-background-accent-1: white;
  }

  &[data-size='s'] {
    &:hover:not([disabled]) {
      background-color: var(--apf-purple-lt-color);
      border: 0.063rem solid var(--apf-purple-color);
    }

    &:active:not([disabled]) {
      --tui-background-accent-1-pressed: var(--apf-purple-color);
      background-color: white;
    }
  }
}

// Seller - Accent
[tuiTheme='seller'] [tuiAppearance][data-appearance='accent'][tuiButton] {
  color: white;
  cursor: pointer;
  --tui-background-accent-1: var(--apf-blue-color);
  border: 0.063rem solid var(--apf-blue-color);
  --tui-background-accent-2: var(--apf-blue-color);
  --tui-background-accent-2-hover: var(--apf-blue-color);

  &:hover {
  }

  &[disabled] {
    --tui-background-accent-1: var(--apf-blue-lt-color);
    cursor: not-allowed;
  }

  &:active:not([disabled]) {
    --tui-background-accent-2-pressed: var(--apf-blue-color);
  }

  &[data-size='s'] {
    --tui-background-accent-2: var(--apf-blue-color);
    &:hover:not([disabled]) {
      --tui-background-accent-2-hover: var(--apf-blue-dk-color);
      --tui-background-accent-1-hover: var(--apf-blue-dk-color);
    }
  }
}

// Seller - Accent
[tuiTheme='seller']
  [tuiAppearance][data-appearance='outline-accent'][tuiButton] {
  color: var(--apf-blue-color);
  cursor: pointer;
  --tui-background-accent-1: var(--apf-blue-color);
  border: 0.063rem solid var(--apf-blue-color);

  &:hover {
  }

  &[disabled] {
    --tui-background-accent-1: var(--apf-blue-lt-color);
  }

  &:active:not([disabled]) {
    --tui-background-accent-2-pressed: var(--apf-blue-color);
    background-color: var(--apf-white);
  }

  &[data-size='s'] {
    --tui-background-accent-2: var(--apf-blue-color);
    &:hover:not([disabled]) {
      background-color: var(--apf-blue-ltr-color);
    }

    &:active:not([disabled]) {
      background-color: var(--apf-white);
    }
  }
}

// Seller - Accent
[tuiAppearance][data-appearance='success'][tuiButton] {
  color: var(--apf-green-color);
  cursor: pointer;
  background-color: var(--apf-white);
  border: 0.063rem solid var(--apf-green-color);

  &:hover {
  }

  &[disabled] {
    --tui-background-accent-1: var(--apf-green-lt-color);
    cursor: not-allowed;
  }

  &:active:not([disabled]) {
    --tui-background-accent-2-pressed: var(--apf-green-color);
    background-color: var(--apf-white);
  }

  &[data-size='s'] {
    --tui-background-accent-2: var(--apf-green-color);
    &:hover:not([disabled]) {
      background-color: var(--apf-green-lt-color);
    }

    &:active:not([disabled]) {
      background-color: var(--apf-white);
    }
  }
}

[tuiTheme='seller'] {
  --tui-background-accent-1: var(--apf-purple-color);
}

[tuiTheme='seller'] [tuiAppearance][data-appearance='primary'][tuiSwitch] {
  color: var(--apf-purple-color);
  --tui-background-accent-1: var(--apf-gray-lt-color);
  background-color: var(--tui-background-neutral-1);
  cursor: pointer;
}

[tuiTheme='buyer']
  [tuiAppearance][data-appearance='destructive'][tuiIconButton] {
  background-color: rgba(244, 87, 37, 0.12);
  border-radius: var(--apf-border-radius);

  &:hover:not([disabled]) {
    background-color: rgba(244, 87, 37, 0.17);
  }
}

[tuiAppearance][data-appearance='whiteblock'][tuiIconButton][iconstart='chevron-left'] {
  border: 1px solid #292d3266;
  box-shadow: none;
  border-radius: 12px;
  outline: none;
  color: black;
  width: 44px;
  height: 44px;

  &[tuiIconButton]:not([tuiIconButton][data-appearance='icon']):not(
      [tuiIconButton][data-appearance='link']
    )::before {
    color: #292d32e5;
    opacity: 90%;
    font-size: 0.75rem;
  }

  &[tuiIconButton]:not([tuiIconButton][data-appearance='icon'])::before {
    color: #292d32e5;
    opacity: 90%;
    font-size: 0.75rem;
  }
}

[tuiAppearance][data-appearance='flat'] {
  &[data-size='s'] {
    box-shadow: none;
  }
}
